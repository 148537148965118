import React from "react";
import "./App.css";
import ".";

import "./assets/css/main.css";
import "./assets/css/util.css";
import logo from "./assets/images/logo.png";
import background from "./assets/images/bg01.jpg";



function App() {
  return (
    <div className="App">
      <div
        className="bg-img1 size1 overlay1 p-b-35 p-l-15 p-r-15"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="flex-col-c p-t-160 p-b-215 respon1">
          <div className="wrappic1">
            <a href="/">
              <img src={logo} style={{height:"100px"}} alt="IMG" />
            </a>
          </div>

          <h3 className="l1-txt1 txt-center p-t-30 p-b-100">Coming Soon</h3>

          <div className="cd100"></div>
        </div>

        <div className="flex-w flex-c-m p-b-35">
          <a
            href="https://www.linkedin.com/company/immiguide-ai"
            target="_blank"
            className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5" rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>

          <a
            href="https://twitter.com/immiguideAI"
            target="_blank"
            className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5" rel="noreferrer"
          >
            <i className="fa-brands fa-twitter"></i>
          </a>

          <a
            href="https://www.instagram.com/immiguide.ai/"
            target="_blank"
            className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5" rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
